<template>
  <loader
    v-if="!apiResolve"
    inner-class="{color: red,'text-align': center,padding: 25px,position: relative,top: 175px,left: 10px}"
  />
  <div v-else-if="apiResolve">
    <div class="container-main">
      <div class="top-inner-band">
        <div class="bck-btn" @click="back()">
          <button><img src="/images/bckbutton.svg" /></button>
        </div>
        <h3>{{ $root.translation.leaderboard }}</h3>
      </div>
    </div>
    <div class="container-main" v-if="leaderboards.length">
      <div class="tb-ar">
        <div id="Daily" class="tabcontent">
          <h3><img :src="$root.LEADERBOARD.thumbnail_path" /></h3>
          <p>
            <span style="font-size: xx-large" class="font-shadow">
              {{ title }} </span
            ><br />
            <span v-if="!show">{{ getDATE() }}</span>
          </p>
          <p v-if="show">
            <select
              v-model="ldbDate"
              @change="ldb($event)"
              class="form-control"
              style="margin-left: 20px;"
            >
              <option disabled value="">Please select date</option>
              <option
                v-for="tournament_object in dateList"
                :key="tournament_object.date"
                :value="tournament_object.date"
                :selected="checkSelectedDate(tournament_object.date)"
              >
                {{ getDATE(tournament_object.date) }}
              </option>
            </select>
          </p>
          <top-score :leaderboards="leaderboards" />
        </div>
      </div>
    </div>
    <div v-else class="tournament_not_registered">
      <div style="text-align: center;margin: 10px;">
        {{ message }}
        <!-- <img src="../../assets/sorry.svg" /> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import TopScore from "@/components/TopScore";
import Loader from "@/components/Loader";
import moment from "moment";
//import $ from "jQuery";

export default {
  name: "Enrolled",
  components: {
    "top-score": TopScore,
    loader: Loader
  },
  data: () => {
    return {
      error: false,
      message: "",
      leaderboards: [],
      apiResolve: false,
      ldbDate: "",
      title: "",
      show: false,
      dateList: [],
      date_map_to_touenament: {}
    };
  },
  async created() {
    try {
      this.title = decodeURIComponent(this.$route.query.title);
      let userLocalStorageData = this.$root.get_localstorage_user_data(
        "userInfo"
      );
      let { msisdn } = userLocalStorageData;
      const host = this.$root.url();
      const path = "api/v1/hullor/game/leaderBoard";
      const url = `${host}/${path}`;
      let data = {
        app_type: "wap",
        msisdn: msisdn,
        tournamentId: this.$route.params.tournamentId
      };
      let response = await axios.post(url, data, {
        headers: {
          token: "f3827eeb536655b578de9aaeb53acf73"
        }
      });
      this.apiResolve = true;
      this.$root.homeApiCall = true;
      if (response.data.status === "SUCCESS") {
        let {
          tournamest_list,
          date_map_to_touenament,
          ldbDate
        } = response.data;
        this.ldbDate = ldbDate;
        this.dateList = tournamest_list;
        this.date_map_to_touenament = date_map_to_touenament;
        this.leaderboards = response.data.leaderboard;
        let ldbActiveTime = response.data.ldb_active_time;
        if (ldbActiveTime !== undefined) {
          let splitedData = ldbActiveTime.split("~");
          if (splitedData.length === 2) {
            let minRange = this.strToInt(splitedData[0]);
            let maxRange = this.strToInt(splitedData[1]);
            let currentHour = this.strToInt(moment().format("HH"));
            this.show = this.inRange(currentHour, minRange, maxRange);
            console.log(`hour :${currentHour},show:${this.show}`);
          } else {
            this.show = false;
          }
        }
        // for (let i = 1; i <= this.ldbPreviousDays; i++) {
        //   let loop_tournament_end_date = this.subtracted_format_date(
        //     tournament_end_date,
        //     tournament_type * (i - 1)
        //   );
        //   let tournament_start_date = this.subtracted_format_date(
        //     tournament_end_date,
        //     tournament_type * i
        //   );
        //   this.dateList.push(
        //     `${this.add_date(
        //       tournament_start_date,
        //       1
        //     )}~${loop_tournament_end_date}`
        //   );
        // }
      } else {
        this.message = response.data.message;
      }
      let { LEADERBOARD, FOOTER_LOGO } = response.data.allImages;
      this.$root.bannerImage(LEADERBOARD, "LEADERBOARD", false);
      this.$root.bannerImage(FOOTER_LOGO, "footer_logo", false);
      this.$root.translation = response.data.translation;
    } catch (e) {
      console.log(e);
      this.apiResolve = true;
      this.error = true;
      this.message = "Something wrong, Please try again later";
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    getDATE(date) {
      date = date === undefined ? this.ldbDate : date;
      let splited_array = date.split("~");
      return `${moment(splited_array[0]).format("D MMMM YYYY")} - ${moment(
        splited_array[1]
      ).format("D MMMM YYYY")}`;
    },
    async ldb() {
      try {
        let userLocalStorageData = this.$root.get_localstorage_user_data(
          "userInfo"
        );
        let { msisdn } = userLocalStorageData;
        const host = this.$root.url();
        const path = "api/v1/hullor/game/leaderBoard";
        const url = `${host}/${path}`;
        let data = {
          app_type: "wap",
          msisdn: msisdn,
          tournamentId: this.date_map_to_touenament[this.ldbDate],
          ldbDate: this.ldbDate
        };
        let response = await axios.post(url, data, {
          headers: {
            token: "f3827eeb536655b578de9aaeb53acf73"
          }
        });
        if (response.data.status === "SUCCESS") {
          this.leaderboards = response.data.leaderboard;
        } else {
          this.leaderboards = [];
          this.message = response.data.message;
        }
      } catch (e) {
        this.leaderboards = [];
      }
    },
    inRange(x, min, max) {
      return (x - min) * (x - max) <= 0;
    },
    strToInt(str = "") {
      return parseInt(str);
    },
    checkSelectedDate(date) {
      return date === this.ldbDate;
    },
    subtracted_format_date(date = "", decrement = 0, format = "YYYY-MM-DD") {
      return moment(date)
        .subtract(decrement, "days")
        .format(format);
    },
    add_date(date = "", increment = 0, format = "YYYY-MM-DD") {
      return moment(date)
        .add(increment, "days")
        .format(format);
    }
  }
};
</script>
