<template>
  <div>
    <div
      class="lrds-lists"
      v-for="leaderboard in leaderboards"
      :key="leaderboard.position"
      :class="{ ld: leaderboard.position === 'র‌্যাঙ্ক' }"
    >
      <div class="left-side">
        <ul>
          <li>{{ leaderboard.name }}</li>
        </ul>
      </div>
      <div class="right-side">
        <ul>
          <li>{{ leaderboard.score }}</li>
          <li v-if="['1', '2', '3'].includes(leaderboard.position)">
            <img :src="position(leaderboard.position)" />
          </li>
          <li v-else>{{ leaderboard.position }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopScore",
  props: ["leaderboards"],
  methods: {
    position(position = 1) {
      let img = `pos-${position}.svg`;
      return `/images/${img}`;
    }
  }
};
</script>

<style scoped>
.ld {
  background-color: #d7b282;
  color: #ffffff;
}
</style>
